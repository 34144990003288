import styled from "styled-components";

export const Container = styled.div`

    width: 100%;
    height: 50rem;

    display: flex;
    align-items: center;
    justify-content: center;

    img{
        width: 30rem;
        height: 30rem;
        border-radius: 50%;
    }
`;
