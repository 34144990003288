import { Container } from './styles'

import logoImg from '../../assets/logo.jpg'

export default function Logo() {
    return (
        <Container>
            <img src={logoImg} alt="" />
        </Container>
    )
}