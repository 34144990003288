import { Container, Content, Links, Link } from './styles'
import Logo from '../Logo'

export default function Home() {
    return (
        <Container>
            <Content>
                <Logo />
                <Links>
                    <h1>Selecione nossa unidade mais próxima de você: </h1>
                    <Link href='https://pedir.delivery/app/barbosatelepizza'>Sarzedo</Link>
                    <Link href='https://pedir.delivery/app/barbosa-igarape'>Igarapé</Link>
                </Links>
            </Content>
        </Container>
    )

}