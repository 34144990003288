import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    :root {
        --white: #ffffff;
        --gray-100: #e1e1e6;
        --gray-300: #a8a8b3;
        --gray-800: #29292e;
        --gray-850: #1f2729;
        --gray-900: #121214;

        --cyan-500: #61dafb;
        --yellow-500: #eba417;
    }

    @media (max-width: 1920px) {
        html {
            font-size: 63.75%;
        }
    }
    
    @media (max-width: 1600px) {
        html {
            font-size: 47.5%;
        }
    
    }

    @media (max-width: 1366px) {
        html {
            font-size: 47.5%;
        }
    }

    @media (max-width: 414px) {
        html {
            font-size: 40%;
        }
    }

    @media (max-width: 375px) {
        html {
            font-size: 35%;
        }
    }
    

    body {
        background: #181920;
        color: var(--white);
        overflow: hidden;
    }

    body,
    input,
    textarea,
    select,
    button {
        font: 300 1rem "Poppins", sans serif;
        outline: none;
    }

    button {
        cursor: pointer;
    }

    a {
        color: inherit;
        text-decoration: none;
    }
`;