import styled from "styled-components";

export const Container = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100vw;
    height: 100vh;

`;

export const Content = styled.div`

    display: flex;
    flex-direction: column;

    width: 95%;
    height: 95%;

    border: 2px solid #FEDB03;
    border-radius: 1rem;

    h1{
        font-size: 3.5rem;
        font-weight: 300;

        text-align: center;

        margin-bottom: 2rem;
    }
`

export const Links = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

`

export const Link = styled.a`

    width: 95%;
    height: 10rem;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 4rem;
    color: #FEDB03;

    text-align: center;
    background-color: #2F323C;

    border: 1px solid #FEDB03;
    border-radius: 1rem;

    margin: 1rem;

`;

export const Line = styled.div`

    width: 70%;
    border: 1px solid #FEDB03;
    margin: 1rem 0;

`